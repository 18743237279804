@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-in-from-bottom {
    from {
      transform: translateY(100%); 
    }
  
    to {
      transform: translateY(0);
    }
  }
  .animate-slide-in-from-bottom {
    animation: slide-in-from-bottom 1000ms ease-out forwards;
  }
  /* .animate-slide-in-from-bottom {
    animation-delay: ;
  } */